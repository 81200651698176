import React from 'react'
import loadable from '@loadable/component'
// import CurrImg from '../../assets/image/svg/currency.inline.svg'
import { Row } from 'react-bootstrap'
import * as userActions from '../../store/actions/userActions'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

const Select = loadable(() => import('../Core/Select'))

const currs = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'JPY', label: 'JPY' },
  { value: 'GBP', label: 'GBP' },
  { value: 'AUD', label: 'AUD' },
  { value: 'CAD', label: 'CAD' },
  { value: 'HKD', label: 'HKD' },
  { value: 'KRW', label: 'KRW' },
  { value: 'RUB', label: 'RUB' },
  { value: 'BRL', label: 'BRL' },
  { value: 'BTC', label: 'BTC' },
  { value: 'ETH', label: 'ETH' },
  { value: 'ARS', label: 'ARS' },
  { value: 'CHF', label: 'CHF' },
  { value: 'CNH', label: 'CNH' },
  { value: 'CZK', label: 'CZK' },
  { value: 'DAI', label: 'DAI' },
  { value: 'IDR', label: 'IDR' },
  { value: 'KZT', label: 'KZT' },
  { value: 'NGN', label: 'NGN' },
  { value: 'PLN', label: 'PLN' },
  { value: 'SGD', label: 'SGD' },
  { value: 'TRY', label: 'TRY' },
  { value: 'UAH', label: 'UAH' },
]

const CurrSelector = props => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const userCurr = useSelector(state => state.user.selectedCurr)
  const { white } = props
  const csrfCoinigy = useSelector(state => state.user.csrf_token)
  const userSettings = useSelector(state => state.user.user_settings)
  const settingsCurr =
    typeof userSettings !== 'undefined'
      ? userSettings.pref_native_currency
      : 'usd'
  const csrfCookie = useSelector(state => state.user.csrfCookie)
  const userSessionExpired = useSelector(state => state.user.session_expired)
  let newToken =
    /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie) !== null
      ? /coinigy\_csrf\_token\=([^$;]*)/gi.exec(document.cookie)[1]
      : ''

  // const result = userSessionExpired === true ? currs.find( ({ value }) => value === userCurr ) : currs.find( ({ value }) => value === settingsCurr);

  let indexOfResult =
    userSessionExpired === true
      ? currs
          .map(function (e) {
            return e.value
          })
          .indexOf(userCurr)
      : currs
          .map(function (e) {
            return e.value
          })
          .indexOf(settingsCurr)

  const handleCurrChange = async selectedCurr => {
    if (csrfCookie !== csrfCoinigy) {
      dispatch(userActions.fetchCsrf())
    }
    if (userSessionExpired === false) {
      await dispatch(userActions.saveUserCurr(newToken, selectedCurr))
      await dispatch(userActions.fetchExchRate(selectedCurr))
      await dispatch(userActions.fetchUserSettings(newToken))
    }
    await dispatch(userActions.fetchExchRate(selectedCurr))
  }

  if (indexOfResult !== -1) {
    return (
      <Row className="mx-0">
        {/* {white === true ? (
          <CurrImg
            data-testid="lightMode-with-selector-data"
            width="16"
            height="40"
            style={{ filter: 'invert(80%)' }}
            className={`mr-1`}
            alt="Currency selector"
          />
        ) : (
          <CurrImg
            data-testid="darkMode-with-selector-data"
            width="16"
            height="40"
            style={{ filter: 'invert(20%)' }}
            className={`mr-1`}
            alt="Currency selector"
          />
        )} */}
        <Select
          aria-label={t('currency')}
          userDarkMode={white}
          className="currencies"
          isSearchable={false}
          options={currs}
          borderRadius={0}
          defaultValue={currs[indexOfResult]}
          onChange={option => handleCurrChange(option.value)}
          components={{ IndicatorSeparator: () => null }}
        />
      </Row>
    )
  } else
    return (
      <Row className="mx-0">
        <span>...</span>
      </Row>
    )
}

export default CurrSelector
